import React from 'react';
import '../styles/Modal.scss'; // You need to create this CSS file to style your modal
import AllBrands from './AllBrands';

const Footer = () => {
  return (
    <div style={{marginBottom:"4em"}}>
    {/* <AllBrands/> */}
    <h5 style={{opacity:"20%", textAlign:"center", padding:"1em 0"}} className="semibold">Made with ❤️ in 🇮🇳 <br/>Co-developed with ChatGPT
    <br/>Designed by Karan Kapoor.</h5>
    </div>
  );
};

export default Footer;
