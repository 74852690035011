import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/TagsBar.scss";

class TagsBarSupabase extends Component {
  componentDidMount() {
    // Remove the default "All" selection
    // if (!this.props.activeFilter) {
    //   this.props.onTagClick("All");
    // }
  }

  onTagClick = (tagName) => {
    // Only call onTagClick to trigger scrolling, not to set active state
    this.props.onTagClick(tagName);
  };

  handleSortButtonClick = () => {
    const newSortOrder = this.props.sortOrder === "asc" ? "desc" : "asc";
    this.props.onSortChange(newSortOrder);
  };

  render() {
    const { tags, currentGroupInView, sortOrder } = this.props;
    const sortIcon = sortOrder === "asc" ? faArrowDown : faArrowUp;
    const sortButtonText = sortOrder === "asc" ? "Price Asc" : "Price Desc";

    if (tags.length <= 1) {
      return null;
    }

    return (
      <div className="parent">
        <div className="bar">
          {tags.map((tag) => (
            <span
              key={tag.id}
              className={`filter-tag ${currentGroupInView === tag.name ? "in-view" : ""}`}
              onClick={() => this.onTagClick(tag.name)}
            >
              {tag.name}
            </span>
          ))}
        </div>
        <div className="bar-sort-option">
          <button
            className={`bar-sort semibold`}
            onClick={this.handleSortButtonClick}
          >
            {sortButtonText} <FontAwesomeIcon icon={sortIcon} />
          </button>
        </div>
      </div>
    );
  }
}

export default TagsBarSupabase;