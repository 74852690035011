import React, { useEffect, useState, useRef } from "react";
import supabase from "../supabaseclient";
import "../styles/AllBrands.scss";

const AllBrands = ({ isOpen, onToggle, buttonRef }) => {
  const [brandsByTag, setBrandsByTag] = useState({});
  const [totalBrandCount, setTotalBrandCount] = useState(0);
  const allBrandsRef = useRef(null);

  useEffect(() => {
    const fetchBrands = async () => {
      const { data, error } = await supabase
        .from("brands_tags")
        .select(
          `
          tag_id,
          tags (name),
          brand_id,
          brand_summary:brand-summary (name, website)
        `
        )
        .order("name", { referencedTable: "tags" })
        .order("name", { referencedTable: "brand-summary" });

      if (error) {
        console.error("Error fetching brands:", error);
        return;
      }

      console.log("Fetched Data:", data);

      const groupedData = data.reduce((acc, curr) => {
        const tagName = curr.tags?.name;
        const brandName = curr.brand_summary?.name;
        const brandWebsite = curr.brand_summary?.website;

        if (tagName && brandName) {
          if (!acc[tagName]) {
            acc[tagName] = [];
          }
          acc[tagName].push({ name: brandName, website: brandWebsite });
        }

        return acc;
      }, {});

      setBrandsByTag(groupedData);
      setTotalBrandCount(new Set(data.map(item => item.brand_id)).size);
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    if (isOpen && buttonRef.current && allBrandsRef.current) {
      const { bottom, left } = buttonRef.current.getBoundingClientRect();
      allBrandsRef.current.style.top = `${bottom}px`;
      // allBrandsRef.current.style.left = `${left}px`;  // Align with left edge
    }
  }, [isOpen, buttonRef]);

  // Function to add UTM parameters to URLs
  const addUtmParams = (url) => {
    if (!url) return '#';
    const baseUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    const utmParams = new URLSearchParams({
      utm_source: 'indiefinds',
      utm_medium: 'banner'
    });
    return `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}${utmParams.toString()}`;
  };

  return (
    <>
      {isOpen && <div className="overlay" onClick={onToggle}></div>}
      <div
        ref={allBrandsRef}
        className={`all-brands ${isOpen ? "open" : ""}`}
      >
        <div className="container">
          <div className="brands">
            {Object.keys(brandsByTag).map((tag) => (
              <div key={tag} className="column">
                <h5>{tag} ({brandsByTag[tag].length})</h5>
                <ul>
                  {brandsByTag[tag].map((brand, index) => (
                    <li key={index}>
                      <a
                        href={addUtmParams(brand.website)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brand.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBrands;