import React, { useState, useEffect, useRef } from "react";
import BrandCardSupabase from "../Supabase/BrandCardSupabase";
import "../styles/BrandGrid.scss";
import supabase from "../supabaseclient";
import PlaceholderCard from "./PlaceholderCard";
import TabBar from "./TabBar";

const BrandsList = ({ categoryId, activeFilter, sortOrder }) => {
  const [groupedBrands, setGroupedBrands] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const groupRefs = useRef({});
  const listRef = useRef(null);

  useEffect(() => {
    const fetchBrands = async () => {
      setIsLoading(true);

      if (categoryId === "top-brands") {
        const { data: topBrandsData, error: topBrandsError } = await supabase
          .from("brand-summary")
          .select("*")
          .eq("top brand", true)
          .order("avgPrice", { ascending: sortOrder === "asc" });

        if (topBrandsError) {
          console.error("Error fetching top brands:", topBrandsError);
          setIsLoading(false);
          return;
        }

        setGroupedBrands({ "Top Brands": topBrandsData });
      } else {
        const { data: tags, error: tagsError } = await supabase
          .from("tags")
          .select("id, name")
          .eq("category_id", categoryId);

        if (tagsError) {
          console.error("Error fetching tags:", tagsError);
          setIsLoading(false);
          return;
        }

        const { data: brandsTags, error: brandsTagsError } = await supabase
          .from("brands_tags")
          .select("brand_id, tag_id")
          .in("tag_id", tags.map(tag => tag.id));

        if (brandsTagsError) {
          console.error("Error fetching brand-tags:", brandsTagsError);
          setIsLoading(false);
          return;
        }

        const brandIds = [...new Set(brandsTags.map((bt) => bt.brand_id))];

        const { data: fetchedBrands, error: brandsError } = await supabase
          .from("brand-summary")
          .select("*")
          .in("id", brandIds)
          .order("avgPrice", { ascending: sortOrder === "asc" });

        if (brandsError) {
          console.error("Error fetching brands:", brandsError);
          setIsLoading(false);
          return;
        }

        const grouped = {};
        tags.forEach((tag) => {
          const filteredBrands = fetchedBrands.filter((brand) =>
            brandsTags.some((bt) => bt.brand_id === brand.id && bt.tag_id === tag.id)
          );

          grouped[tag.name] = filteredBrands.sort((a, b) => a.rank - b.rank);
        });

        setGroupedBrands(grouped);
      }

      setIsLoading(false);
    };

    fetchBrands();

    return () => setGroupedBrands({});
  }, [categoryId, sortOrder]);

  useEffect(() => {
    const handleScroll = () => {
      if (!listRef.current) return;

      const scrollPosition = listRef.current.scrollTop;
      let activeGroup = "";

      for (const [groupName, ref] of Object.entries(groupRefs.current)) {
        if (ref && ref.offsetTop <= scrollPosition + 100) {
          activeGroup = groupName;
        } else {
          break;
        }
      }

      setActiveTab(activeGroup);
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [groupedBrands]);

  useEffect(() => {
    if (Object.keys(groupedBrands).length > 0) {
      setActiveTab(Object.keys(groupedBrands)[0]);
    }
  }, [groupedBrands]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (groupRefs.current[tabName]) {
      groupRefs.current[tabName].scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderBrands = (brands) => (
    <div className="brand-grid">
      {brands.map((brand) => (
        <BrandCardSupabase
          key={brand.id}
          brandId={brand.id}
          className="brand-card-fade-in"
        />
      ))}
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="brand-grid">
          {Array.from({ length: 6 }, (_, index) => (
            <PlaceholderCard key={index} />
          ))}
        </div>
      );
    }

    const tags = Object.keys(groupedBrands);

    if (tags.length <= 1) {
      // If there's only one tag or no tags, render brands without tag names
      return renderBrands(Object.values(groupedBrands)[0] || []);
    }

    // If there are multiple tags, render with tag names
    return (
      <>
        {tags.length > 1 && (
          <TabBar
            tabs={tags}
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        )}
        {Object.entries(groupedBrands).map(([tagName, brands]) => (
          <div key={tagName} ref={el => groupRefs.current[tagName] = el}>
            {/* <div className="tag-name">
              <h4>{tagName}</h4>
            </div> */}
            {renderBrands(brands)}
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default BrandsList;