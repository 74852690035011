import React, { useState, useEffect, useCallback, useRef } from "react";
import TagsBarSupabase from "../Supabase/TagsBarSupabase";
import BrandsList from "../components/BrandsList";
import supabase from "../supabaseclient";
import "../styles/CategoryDetails.scss";
import Footer from "../components/Footer";

const CategoryDetails = ({ categoryId }) => {
  const [tags, setTags] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentGroupInView, setCurrentGroupInView] = useState("All");
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchTags = async () => {
      if (categoryId !== "top-brands") {
        const { data: tagsData, error: tagsError } = await supabase
          .from("tags")
          .select("id, name")
          .eq("category_id", categoryId);

        if (tagsError) {
          console.error("Failed to fetch tags:", tagsError);
          return;
        }

        setTags(tagsData);
      }
    };

    fetchTags();
  }, [categoryId]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const groups = containerRef.current.querySelectorAll('h5'); // Assuming each group starts with an h5 tag
    let closestGroup = null;
    let closestDistance = Infinity;

    groups.forEach((group) => {
      const groupRect = group.getBoundingClientRect();
      const distance = Math.abs(groupRect.top - containerRect.top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestGroup = group.textContent;
      }
    });

    if (closestGroup && closestGroup !== currentGroupInView) {
      setCurrentGroupInView(closestGroup);
    }
  }, [currentGroupInView]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const handleTagClick = useCallback((tagName) => {
    setActiveFilter(tagName);
    // Find the corresponding group and scroll to it
    const groups = containerRef.current.querySelectorAll('h5');
    const targetGroup = Array.from(groups).find(group => group.textContent === tagName);
    if (targetGroup) {
      targetGroup.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSortChange = useCallback((newSortOrder) => {
    setSortOrder(newSortOrder);
  }, []);

  return (
    <div className="category-parent" ref={containerRef}>
      {/* {categoryId !== "top-brands" && (
        <TagsBarSupabase
          tags={tags}
          activeFilter={activeFilter}
          currentGroupInView={currentGroupInView}
          sortOrder={sortOrder}
          onTagClick={handleTagClick}
          onSortChange={handleSortChange}
        />
      )} */}
      <BrandsList
        categoryId={categoryId}
        activeFilter={activeFilter}
        sortOrder={sortOrder}
        onGroupChange={setCurrentGroupInView}
      />
      {/* <Footer /> */}
    </div>
  );
};

export default CategoryDetails;