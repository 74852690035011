import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./categories/HomePage";
import CategoryDetails from "./categories/CategoryDetails";
import About from "./components/About";
import { Analytics } from "@vercel/analytics/react";
import { DataProvider } from "./data/DataContext";

const App = () => {
  const [isSubmitEmailModalOpen, setIsSubmitEmailModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      openSubmitEmailModal();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const openSubmitEmailModal = () => setIsSubmitEmailModalOpen(true);

  return (
    <DataProvider>
      <Router>
        <Analytics />
        <Routes>
          <Route path="/" element={<Navigate replace to="/category/top-brands" />} />
          <Route path="/home" element={<Navigate replace to="/category/top-brands" />} />
          <Route path="/category/:categoryId" element={<HomePage />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </DataProvider>
  );
};
export default App;