import React from "react";
import {
  Trophy,
  SuitcaseRolling,
  Backpack,
  CoatHanger,
  TShirt,
  Sneaker,
  Desktop,
  HandHeart,
  DiceFive,
  Watch,
  ShirtFolded
} from "@phosphor-icons/react";
import "./CategoryCard.scss";

// Define your icon components and their selected states
const iconComponents = {
  Trophy: {
    default: (props) => <Trophy {...props} weight="light" />,
    selected: (props) => <Trophy {...props} weight="duotone" />
  },
  Luggage: {
    default: (props) => <SuitcaseRolling {...props} weight="light" />,
    selected: (props) => <SuitcaseRolling {...props} weight="duotone" />
  },
  Bags: {
    default: (props) => <Backpack {...props} weight="light" />,
    selected: (props) => <Backpack {...props} weight="duotone" />
  },
  Clothing: {
    default: (props) => <CoatHanger {...props} weight="light" />,
    selected: (props) => <CoatHanger {...props} weight="duotone" />
  },
  Tshirts: {
    default: (props) => <TShirt {...props} weight="light" />,
    selected: (props) => <TShirt {...props} weight="duotone" />
  },
  Sneaker: {
    default: (props) => <Sneaker {...props} weight="light" />,
    selected: (props) => <Sneaker {...props} weight="duotone" />
  },
  Desktop: {
    default: (props) => <Desktop {...props} weight="light" />,
    selected: (props) => <Desktop {...props} weight="duotone" />
  },
  SelfCare: {
    default: (props) => <HandHeart {...props} weight="light" />,
    selected: (props) => <HandHeart {...props} weight="duotone" />
  },
  Games: {
    default: (props) => <DiceFive {...props} weight="light" />,
    selected: (props) => <DiceFive {...props} weight="duotone" />
  },
  Watches: {
    default: (props) => <Watch {...props} weight="light" />,
    selected: (props) => <Watch {...props} weight="duotone" />
  },
  ShirtFolded: {
    default: (props) => <ShirtFolded {...props} weight="light" />,
    selected: (props) => <ShirtFolded {...props} weight="duotone" />
  }
};

const CategoryCard = ({ id, name, color, icon, onSelect, isSelected }) => {
  const cardClassName = `category-card ${isSelected ? "category-card--selected" : ""}`;

  console.log('CategoryCard:', id, 'isSelected:', isSelected);

  const iconColors = {
    Trophy: "var(--trophy-color)",
    Luggage: "var(--luggage-color)",
    Bags: "var(--bags-color)",
    Clothing: "var(--clothing-color)",
    Tshirts: "var(--tshirt-color)",
    Sneaker: "var(--sneaker-color)",
    Desktop: "var(--desktop-color)",
    SelfCare: "var(--selfcare-color)",
    Games: "var(--games-color)",
    Watches: "var(--watches-color)",
    ShirtFolded: "var(--shirtfolded-color)"
  };

  // Choose the icon component based on isSelected state
  const IconComponent = isSelected 
    ? iconComponents[icon]?.selected 
    : iconComponents[icon]?.default;

  const colorStyle = isSelected ? iconColors[icon] : "var(--grey-500)";

  return (
    <div className={cardClassName} onClick={() => onSelect(id)}>
      <IconComponent 
        size={26} 
        color={colorStyle}
        className="category-icon" 
      />
      <h4 style={{ color: colorStyle }} className="category-name">{name}</h4>
    </div>
  );
};

export default CategoryCard;
