// Modal.js
import React, { useState } from 'react';
import '../styles/Modal.scss'; // Ensure the path to your CSS file is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className='modal-action'>
          <h3 style={{ color: "var(--primary-color" }} className='semibold'>Suggest a Brand</h3>
          <button className="close-email" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {isLoading && (
          <div className="modal">
            <div className="loader"></div> {/* Define this loader in your CSS */}
          </div>
        )}
        <iframe
          style={{ borderWidth: "0", display: isLoading ? "none" : "block" }}
          src="https://forms.gle/djsp8Z4biEL2ALx6A"
          title="Suggest a brand form"
          width="100%"
          height="100%"
          onLoad={() => setIsLoading(false)}
        ></iframe>
      </div>
    </div>
  );
};

export default Modal;
