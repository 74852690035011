import React, { useState } from "react";
import "../styles/SubmitEmail.scss";
import supabase from "../supabaseclient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const SubmitEmail = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setMessage("Please enter a valid email address.");
      setMessageType("error");
      return;
    }

    try {
      const { error } = await supabase.from("emails").insert([{ email }]);
      if (error) throw error;

      setMessage("Email submitted successfully!");
      setMessageType("success");
      setEmail(""); // Clear the field
      setTimeout(() => onClose(), 1000); // Close the overlay after 2 seconds
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
    }
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="overlay">
      <div className="suggest-dialog">
        <div className="email-parent">
          {/* <button onClick={onClose} style={{ float: "right" }}>
            Close
          </button> */}
          <button className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
          <h3 className="semibold">
            Signup for the IndieFinds Newsletter to discover brands, right from your inbox.
          </h3>
          <h5>Once every two weeks, No spamming, I swear 🤝</h5>
          <div className="email-input">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <button className="suggest-button" onClick={handleSubmit}>
              Submit Email
            </button>
          </div>
          {message && <div className={`message ${messageType}`}>{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default SubmitEmail;
