// TabBar.js
import React from 'react';
import '../styles/TabBar.scss';

const TabBar = ({ tabs, activeTab, onTabClick }) => {
  if (tabs.length <= 1) return null;

  return (
    <div className="tabs-bar">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`tab ${activeTab === tab ? "active" : ""}`}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabBar;