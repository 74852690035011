import React, { useState, useEffect } from "react";
import { ArrowUpRight, Copy } from "phosphor-react"; // Import Phosphor icon
import CarouselSupabase from "./CarouselSupabase";
import "../styles/BrandGrid.scss";
import supabase from "../supabaseclient";
import PlaceholderCard from "../components/PlaceholderCard";
import Toast from "../components/Toast";

const BrandCardSupabase = ({ brandId }) => {
  const [brandDetails, setBrandDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [tags, setTags] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [toastVisible, setToastVisible] = useState(false); // State to control Toast visibility

  useEffect(() => {
    const fetchBrandDetails = async () => {
      setIsLoading(true);
      const { data: brandData, error: brandError } = await supabase
        .from("brand-summary")
        .select("*")
        .eq("id", brandId)
        .single();

      if (brandError) {
        setFetchError(brandError.message);
        setIsLoading(false);
      } else {
        setBrandDetails(brandData);

        const { data: productsData, error: productsError } = await supabase
          .from("products")
          .select("image, productlink")
          .eq("brand-id", brandId);

        if (productsError) {
          setFetchError(productsError.message);
        } else {
          setProducts(productsData);
        }

        const { data: brandsTagsData, error: brandsTagsError } = await supabase
          .from("brands_tags")
          .select("tag_id")
          .eq("brand_id", brandId);

        if (brandsTagsError) {
          setFetchError(brandsTagsError.message);
        } else {
          const tagIds = brandsTagsData.map((tag) => tag.tag_id);
          const { data: tagNamesData, error: tagNamesError } = await supabase
            .from("tags")
            .select("name")
            .in("id", tagIds);

          if (tagNamesError) {
            setFetchError(tagNamesError.message);
          } else {
            setTags(tagNamesData.map((tag) => tag.name));
          }
        }

        setIsLoading(false);
      }
    };

    fetchBrandDetails();
  }, [brandId]);

  if (fetchError) return <div>Error: {fetchError}</div>;

  if (isLoading) {
    return <PlaceholderCard />;
  }

  const imageUrls = products.map((product) => product.image);
  const productLinks = products.map((product) => product.productlink);
  const tagsString = tags.join(", ");

  // Function to ensure the URL is properly formatted
  const formatUrl = (url) => {
    if (!url) return '#';
    let formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    
    // Append campaign parameters
    const campaignParams = new URLSearchParams({
      utm_source: 'indiefinds',
      utm_medium: 'banner'
    });
    
    // Check if the URL already has query parameters
    formattedUrl += formattedUrl.includes('?') ? '&' : '?';
    formattedUrl += campaignParams.toString();
    
    return formattedUrl;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(formatUrl(brandDetails.website));
    setToastVisible(true);
  };

  return (
    <div className="brand-card">
      <Toast
        message="Link copied to clipboard"
        isVisible={toastVisible}
        onClose={() => setToastVisible(false)}
      />
      <CarouselSupabase images={imageUrls} productLinks={productLinks} />
      <div className="copy-info">

        <a
          className="copy-link" onClick={handleCopyClick}>
          <Copy
            size={20}
            color="var(--primary-color)"
            weight="regular"
          />
        </a>

        <div className="info-cta">
          <div className="details">
            <h5 className="brand-tag semibold">
              {brandDetails.priceRange} · {tagsString}
            </h5>
          </div>
          <div className="name">
            <h3 className="bold">
              {brandDetails.name} ·
            </h3>
            <a
              href={formatUrl(brandDetails.website)}
              className="brand-link"
              target="_blank"
              title="open website in new tab"
              rel="noopener noreferrer"
            >
              Shop now{" "}
              <ArrowUpRight
                size={16}
                color="var(--primary-color)"
                weight="bold"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCardSupabase;
