// src/components/Toast.js
import React, { useState, useEffect } from "react";
import "../styles/Toast.scss";

const Toast = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000); // Toast disappears after 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <div className={`toast ${isVisible ? "show" : ""}`}>
      {message}
    </div>
  );
};

export default Toast;
