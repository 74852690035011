// DataContext.js
import React, { createContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cachedData, setCachedData] = useState({});

  const setCache = (key, data) => {
    setCachedData((prevData) => ({
      ...prevData,
      [key]: data,
    }));
  };

  return (
    <DataContext.Provider value={{ cachedData, setCache }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
