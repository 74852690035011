import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faClose } from "@fortawesome/free-solid-svg-icons";
import "../styles/About.scss";
import Footer from "../components/Footer";

const About = ({ onClose }) => {
  return (
    <div className="about-overlay">
      <div className="about">
        <button className="close-about" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <h3 className="semibold">Why I created this website?</h3>
        <h5>
          A few months ago, I went down a rabbit hole trying to find a pair of
          sneakers that look good but don't dent a hole in my pocket.
          <br /><br />
          After endlessly scrolling for a few weeks, I came across a bunch of
          indie brands through instagram ads and some listicles. These brands
          make premium products at a reasonable price which can give top
          international brands a run for their money.
          <br /><br />
          Finally, I bought a pair of Comet sneakers which looked great, felt
          premium, and costed me ₹4000.
          <br /><br />
          This website is an ode to everyone who wants to experiment with new
          styles but finds themselves stuck with the same overpriced brands.
        </h5>
        <br /><br />
        <h3 className="semibold">About Me</h3>
        <h5>
          Hi, my name is Karan. I'm a Designer, Code Learner, & a Photography
          Enthusiast. I recently started learning code again to turn my ideas
          into reality.
          <br /><br />
          I love to help early stage companies through design and
          research. Reach out on{" "}
          <a className="email" href="mailto:karan26796@gmail.com">
            karan26796@gmail.com
          </a>{" "}
          to collaborate on ideas or to help out with this project.
        </h5>
        <br />
        <div className="icon-grid">
          <a href="https://www.kadankapoor.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="social-icon" icon={faGlobe} />
          </a>
          <a href="https://www.linkedin.com/in/karankapoorux" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
          </a>
          <a href="https://www.twitter.com/kadankapoor" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="social-icon" icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com/karankapoorux" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="social-icon" icon={faInstagram} />
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About;