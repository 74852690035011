import React, { useState } from "react";
import { ArrowUpRight } from "phosphor-react";
import "../styles/Carousel.scss";

const CarouselSupabase = ({ images = [], productLinks = [] }) => {
  if (images.length === 0 || productLinks.length === 0) {
    return (
      <div className="carousel">
        <img
          src={`${process.env.PUBLIC_URL}/logo-retro-new.svg`}
          alt="Placeholder"
          className="carousel-image"
        />
      </div>
    );
  }

  const addUtmParams = (url) => {
    const utmParams = new URLSearchParams({
      utm_source: 'indiefinds',
      utm_medium: 'banner'
    });
    return `${url}${url.includes('?') ? '&' : '?'}${utmParams.toString()}`;
  };
  
  return (
    <div className="carousel">
      {images.map((image, index) => (
        <div key={index} className="img-container">
          <img
            src={image}
            className="carousel-image"
          />
          <a
            href={addUtmParams(productLinks[index])}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
            className="carousel-image-link"
          >
            <ArrowUpRight
              size={18}
              color="var(--primary-color)"
              weight="bold"
            />
          </a>
        </div>
      ))}
    </div>
  );
};

export default CarouselSupabase;
