const PlaceholderCard = () => {
    return (
      <div className="placeholder-card">
        <div className="placeholder-image"></div>
        <div className="placeholder-title"></div>
        <div className="placeholder-tag"></div>
      </div>
    );
  };

  export default PlaceholderCard;