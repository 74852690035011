import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import "../styles/HomePage.scss";
import CategoryDetails from "./CategoryDetails";
import supabase from "../supabaseclient";
import SubmitEmail from "../components/SubmitEmail";
import Header from "../components/Header";
import AllBrands from "../components/AllBrands";
import About from "../components/About";

const categoryInfo = {
  'Top Brands': { color: '#ff7b00', icon: 'Trophy' },
  'Luggage': { color: '#1BBE69', icon: 'Luggage' },
  'Bags': { color: '#00482A', icon: 'Bags' },
  'Clothing': { color: '#1BBE69', icon: 'Clothing' },
  'T-shirt/Shirt': { color: '#FD8C54', icon: 'Tshirts' },
  'Sneakers': { color: '#7D44DB', icon: 'Sneaker' },
  'Traditional': { color: '#7D44DB', icon: 'ShirtFolded' },
  'Tech': { color: '#00482A', icon: 'Desktop' },
  'Self Care': { color: '#FF4595', icon: 'SelfCare' },
  'Games': { color: '#7D44DB', icon: 'Games' },
  'Watches': { color: '#FF4595', icon: 'Watches' },
};

function HomePage() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [showSubmitEmail, setShowSubmitEmail] = useState(false);
  const [showAboutOverlay, setShowAboutOverlay] = useState(false);
  const overlayRef = useRef(null);

  const handleSuggestBrandClick = () => {
    console.log("Suggest a Brand button clicked");
  };

  const handleLogoClick = () => {
    setShowAboutOverlay(!showAboutOverlay);
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setShowAboutOverlay(false);
    }
  };

  useEffect(() => {
    if (showAboutOverlay) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAboutOverlay]);

  useEffect(() => {
    const emailModalTimer = setTimeout(() => {
      setShowSubmitEmail(true);
    }, 5000);

    return () => clearTimeout(emailModalTimer);
  }, []);

  return (
    <div className="parent-home">
      <Header onSuggestBrandClick={handleSuggestBrandClick} />

      <div className="content-wrapper">
        <div className="parent-category">
          <CategoryBar
            selectedCategory={categoryId}
            onCategorySelect={(id) => navigate(`/category/${id}`)}
            onLogoClick={handleLogoClick}
          />
        </div>
        <div className="header-brands">
          <CategoryDetails categoryId={categoryId} />
          {showSubmitEmail && (
            <SubmitEmail onClose={() => setShowSubmitEmail(false)} />
          )}
        </div>
        <AllBrands />
      </div>

      {showAboutOverlay && (
        <div ref={overlayRef} className="about-overlay">
          <About onClose={() => setShowAboutOverlay(false)} />
        </div>
      )}
    </div>
  );
}

const CategoryBar = ({ onCategorySelect, selectedCategory, onLogoClick }) => {
  const [categories, setCategories] = useState([]);
  const categoriesFetched = useRef(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCategorySelect = (id) => {
    setSelectedCategoryId(id); // Update state with the selected category ID
    onCategorySelect(id); // Navigate or perform other actions if needed
  };

  useEffect(() => {
    if (!categoriesFetched.current) {
      fetchCategories();
    }
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .order('rank', { ascending: true });

      if (error) {
        throw error;
      } else {
        const topBrandsCategory = {
          id: 'top-brands',
          name: 'Top Brands',
          emoji: '🏆',
          color: categoryInfo['Top Brands'].color,
          icon: categoryInfo['Top Brands'].icon,
          rank: 0
        };

        const processedData = data.map(category => {
          const categoryName = category.name.trim();

          const normalizeCategory = (name) => name.toLowerCase().replace(/[-\/\s]/g, '');

          const matchingCategory = Object.entries(categoryInfo).find(([key, _]) =>
            normalizeCategory(key) === normalizeCategory(categoryName)
          );

          const info = matchingCategory ? matchingCategory[1] : { color: '#CCCCCC', icon: 'Question' };

          return {
            ...category,
            color: info.color,
            icon: info.icon
          };
        });

        const sortedCategories = [topBrandsCategory, ...processedData].sort((a, b) => a.rank - b.rank);

        setCategories(sortedCategories);
        categoriesFetched.current = true;
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  return (
    <div className="categories-bar">
      {categories.map((category) => (
        <CategoryCard
          key={category.id}
          id={category.id}
          name={category.name}
          color={category.color}
          icon={category.icon}
          onSelect={handleCategorySelect} // Pass the handler
          isSelected={selectedCategoryId === category.id} // Compare with selected ID
        />
      ))}
    </div>
  );
};

export default HomePage;
